// import Breakpoint from '../helpers/breakpoint';
//
// /**
//  * Module description...
//  *
//  * @returns {{init: init}}
//  */
// const MainNav = (() => {
//   const DOM = {};
//   const transitionBreakpoint = "md";
//
//   const cacheDOM = () => {
//     DOM.hamburger = document.getElementsByClassName("toggleNav");
//     DOM.toggleNav = document.getElementsByClassName("nav");
//     DOM.menuItems = document.querySelectorAll(".menu-item > a");
//   };
//
//   const toggleNav = () => {
//     const menu = DOM.toggleNav[0];
//     const hamburger = DOM.hamburger[0];
//
//     if (!hamburger.classList.contains("open")) {
//       hamburger.classList.add("open");
//       menu.classList.add("active");
//     } else {
//       hamburger.classList.remove("open");
//       menu.classList.remove("active");
//     }
//   };
//
//
//   const eventListeners = () => {
//     let addMobileNavEventListeners = () => {
//       DOM.hamburger[0].addEventListener("click", toggleNav);
//       Array.from(DOM.menuItems).forEach((HTMLNode) => {
//         HTMLNode.addEventListener("click", toggleNav);
//       });
//     }
//
//     if (Breakpoint.isBreakpointDown(transitionBreakpoint)) {
//       addMobileNavEventListeners();
//     }
//
//     window.addEventListener('resize', function () {
//       if (Breakpoint.isBreakpointDown(transitionBreakpoint)) {
//         addMobileNavEventListeners();
//       } else {
//         DOM.hamburger[0].removeEventListener("click", toggleNav);
//         Array.from(DOM.menuItems).forEach((HTMLNode) => {
//           HTMLNode.removeEventListener("click", toggleNav);
//         });
//       }
//     }, false);
//   };
//
//   const init = () => {
//     cacheDOM();
//     eventListeners();
//   };
//
//   return {
//     init,
//   };
// })();
//
// export default MainNav;

import Breakpoint from '../helpers/breakpoint';

const MainNav = (() => {
  const DOM = {};

  const cacheDOM = () => {
    DOM.hamburger = document.getElementById('MobileMenu');
    DOM.nav = document.getElementById("nav");

  };

  const eventListeners = () => {
    DOM.hamburger.addEventListener('click', function () {
      toggleNav();
    })
  };

  const toggleNav = () => {
    if(DOM.nav.classList.contains('active')) {
      DOM.nav.classList.remove('active');
      DOM.nav.setAttribute( 'style', 'height: 0;' );
    } else {
      DOM.nav.classList.add('active');
      DOM.nav.style.height = DOM.nav.scrollHeight + 'px';
    }
  };

  const init = () => {
    cacheDOM();
    eventListeners();
  };

  return {
    init,
  };
})();

export default MainNav;

